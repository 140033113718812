import { StaticImage } from "gatsby-plugin-image"
import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import React, { useEffect, useRef } from "react"
import { useState } from "react"
import { caseStudies } from "."
import ShowCase from "../../components/CaseStudy/CaseStudyShowCase"
import FlsBanner from "../../components/CaseStudy/FlsBanner"
import {
    FLSImageFour,
    FLSImageOne,
    FLSImageThree,
    FLSImageTwo
} from "../../components/CaseStudy/FLSImage"
import Overview from "../../components/CaseStudy/Overview"
import ContentImage from "../../components/ContentImage/ContentImage"
import Newsletter from "../../components/Form/Newsletter"
import Hello from "../../components/Hello/Hello"
import HeroTwo from "../../components/Hero/HeroTwo"
import Layout from "../../components/layout"
import { SEO } from "../../components/seo"
import TitleContent from "../../components/TitleContent/TitleContent"

gsap.registerPlugin(ScrollTrigger)

const techs = [
    { src: "/case-study/jam.svg" },
    { src: "/case-study/gatsby.svg" },
    { src: "/case-study/wordpress.png" },
    { src: "/case-study/threejs.svg" },
    { src: "/case-study/tailwind.svg" },
    { src: "/case-study/webgl.png" }
]

const tlDefaults = {
    ease: "slow.inOut",
    duration: 1.25
}

const sliderImages = [
    {
        component: FLSImageOne
    },
    {
        text: "The interactive features, like the 3D globe, not only highlight their global reach but also engage visitors in an immersive online experience.",
        component: FLSImageTwo
    },
    {
        text: "In our collaboration with FLS, Saigon Digital crafted a harmonious blend of contemporary design and practical functionality.",
        component: FLSImageThree
    },
    {
        text: "The website has become a cornerstone in FLS Group's digital strategy, effectively enhancing their brand’s digital presence in the logistics and shipping industry.",
        component: FLSImageFour
    }
]

const overview = {
    overview: `FLS Group, a leading player in the logistics and shipping industry, is known for their reliable and efficient services. Their commitment to excellence has established them as a prominent figure in global logistics.
    The goal was to create a fresh, highly interactive website design aligning with FLS Group's company-wide rebranding initiative. A fully responsive website with innovative features, including a 3D globe to illustrate FLS Group's worldwide offices and capabilities.`,
    services: ["Headless CMS", "Design", "Development", "SEO"],
    linkUrl: "https://fls-group.com/"
}

const lhScores = [
    {
        title: "Performance",
        score: 73
    },
    {
        title: "Accessibility",
        score: 89
    },
    {
        title: "Best Practices",
        score: 93
    },
    {
        title: "SEO",
        score: 91
    }
]

const Detail = ({ location }) => {
    const $contentImageWrapper = useRef(null)
    const $contentImagePin = useRef(null)
    const $sliderSection = useRef(null)
    const $slider = useRef(null)
    const $progress = useRef(null)

    const currentSlide = useRef(undefined)
    const next = useRef(0)
    const alreadyEntered = useRef(false)

    const heroData = {
        preheader: "FLS Group",
        title: "Transforming FLS Group’s Online Identity"
    }
    const context = {
        pageName: "SD | Case studies - FLS Group",
        pageUri: location.href
    }

    useEffect(() => {
        const mm = gsap.matchMedia()

        mm.add("(min-width: 1024px)", () => {
            const contentImages =
                $contentImageWrapper.current.querySelectorAll(".content-image")

            contentImages[0].classList.add("is-active")

            ScrollTrigger.create({
                trigger: $contentImageWrapper.current,
                // pin: $contentImagePin.current,
                start: "top top",
                onLeave: () => {
                    contentImages[0].classList.remove("is-active")
                    contentImages[contentImages.length - 1].classList.add(
                        "is-active"
                    )
                },
                // markers: true,
                onUpdate: self => {
                    const progress = Number(self.progress.toFixed(3))
                    const total = contentImages.length + 1

                    contentImages.forEach((_, i) => {
                        const oldIndex = i
                        const newIndex = oldIndex + 1

                        if (
                            progress > oldIndex / total &&
                            progress < newIndex / total
                        ) {
                            contentImages[oldIndex].classList.add("is-active")

                            if (self.direction === 1 && oldIndex > 0) {
                                contentImages[oldIndex - 1].classList.remove(
                                    "is-active"
                                )
                            } else if (
                                self.direction === -1 &&
                                newIndex < contentImages.length
                            ) {
                                contentImages[newIndex].classList.remove(
                                    "is-active"
                                )
                            }
                        }
                    })
                }
            })
        })

        return () => {
            mm.revert()
        }
    }, [])

    useEffect(() => {
        const ctx = gsap.context(() => {
            const outers = gsap.utils.toArray(".slider-outer")
            const inners = gsap.utils.toArray(".slider-inner")
            const images = gsap.utils.toArray(".slider-image")

            gsap.set(outers, { yPercent: 100 })
            gsap.set(inners, { yPercent: -100 })
            gsap.set(images, { scale: 1.3, yPercent: 15 })
            gsap.set($progress.current, { scaleX: 0 })

            ScrollTrigger.create({
                trigger: $sliderSection.current,
                start: "top top",
                // markers: true,
                onEnter: () => {
                    if (!alreadyEntered.current) {
                        slideIn()
                    }

                    alreadyEntered.current = true
                }
            })
        })

        return () => {
            ctx.revert()
        }
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentSlide.current !== undefined) {
            }
        }, 4000)

        return () => {
            clearInterval(interval)
        }
    }, [])

    // Slides a section in on scroll down
    function slideIn() {
        const outers = gsap.utils.toArray(".slider-outer")
        const inners = gsap.utils.toArray(".slider-inner")
        const images = gsap.utils.toArray(".slider-image")
        const texts = gsap.utils.toArray(".slider-text")
        const logos = gsap.utils.toArray(".slider-logo")

        // The first time this function runs, currentSlide is undefined
        const tl = gsap.timeline({
            paused: true,
            defaults: tlDefaults,
            onComplete: () => {
                currentSlide.current = next.current
                next.current = currentSlide.current + 1
                if (next.current > sliderImages.length - 1) {
                    currentSlide.current = sliderImages.length - 1
                    next.current = 0
                }

                const timeout = setTimeout(() => {
                    slideIn()
                }, 2000)
            }
        })

        tl.to(outers[next.current], { yPercent: 0 }, 0)
            .to(inners[next.current], { yPercent: 0 }, 0)
            .to(images[next?.current], { yPercent: 0, scale: 1 }, 0)
            .to(
                $progress.current,
                { scaleX: (next.current + 1) / sliderImages.length },
                0
            )
            .fromTo(
                texts[next?.current],
                { y: 40, opacity: 0 },
                { y: 0, opacity: 1 },
                0
            )
            .fromTo(logos[next?.current], { opacity: 0 }, { opacity: 1 }, 0)

        if (currentSlide?.current !== undefined) {
            tl.to(outers[currentSlide.current], { yPercent: -100 }, 0)
                .to(inners[currentSlide.current], { yPercent: 100 }, 0)
                .to(
                    images[currentSlide.current],
                    { scale: 1.2, yPercent: -15 },
                    0
                )
                .to(
                    texts[currentSlide?.current],
                    { y: -40, opacity: 0, duration: 0.8 },
                    0
                )
                .to(
                    logos[currentSlide?.current],
                    { opacity: 0, duration: 0.8 },
                    0
                )

            tl.add(
                gsap
                    .timeline()
                    .set(outers[currentSlide?.current], {
                        yPercent: 100
                    })
                    .set(inners[currentSlide?.current], {
                        yPercent: -100
                    })
                    .set(images[currentSlide?.current], {
                        yPercent: 15,
                        scale: 1.3
                    })
            )
        }

        tl.play(0)
    }

    return (
        <Layout context={context}>
            <HeroTwo props={heroData} />
            {/* Overview */}
            <Overview {...overview} />
            <FlsBanner className={"mt-16 lg:mt-24 lg:h-[460px]"}>
                <StaticImage
                    src={
                        "../../../static/case-study/fls/full-width-banner-1.png"
                    }
                    alt="FLS mockup"
                    objectFit="cover"
                    className="hidden h-full w-full lg:block"
                />

                <StaticImage
                    src={
                        "../../../static/case-study/fls/full-width-banner-mobile-1.png"
                    }
                    alt="FLS mockup"
                    objectFit="cover"
                    className="h-full w-full lg:hidden"
                />
            </FlsBanner>

            <TitleContent
                title="Navigating New Digital Horizons for FLS Group"
                className="py-14 lg:py-24"
            >
                <p className="mb-4 lg:mb-[88px]">
                    Our team at Saigon Digital took on an exciting challenge. We
                    used JAMstack technology for web development, added Three.js
                    and WebGL to create an interactive 3D globe, and chose
                    Gatsby.js for its remarkable speed and performance. We also
                    used Tailwind CSS for an elegant, responsive design. For
                    managing the website's content, we used Headless WordPress,
                    which allowed for flexible and scalable digital solutions.
                </p>

                <h3 className="mt-6 text-lg font-semibold">
                    Technologies used:
                </h3>
                <div class="mt-6 grid grid-cols-2 gap-4 md:gap-4 lg:grid-cols-3 lg:gap-[30px]">
                    {techs.map(({ src }, index) => {
                        return (
                            <div class="group grid aspect-[160/78] place-items-center border py-2 px-6 transition-all duration-300 hover:shadow-light_shadow">
                                <img
                                    src={src}
                                    className="max-w-full object-contain grayscale transition-all duration-300 group-hover:grayscale-0"
                                    alt="logos"
                                />
                            </div>
                        )
                    })}
                </div>
            </TitleContent>

            <Hello
                link="https://saigon.digital/blog/advancing-web-security-jamstack-versus-traditional-website-development"
                title="Optimise User-Experience with Google Lighthouse"
                lhScores={lhScores}
            >
                <p className="mb-4">
                    Google Lighthouse is an open-source tool for website
                    performance, auditing pages for performance, accessibility,
                    SEO, Progressive Web App and Best Practices.
                </p>
                <p className="mb-4">
                    Using Google Lighthouse as a benchmark, we make sure that
                    FLS Group's website is performing flawlessly with excellent
                    scores.
                </p>
                <p>
                    Here is the performance results:{" "}
                    <a
                        href="/fls-group.com-report.html"
                        target="_blank"
                        download
                        className="font-semibold text-primary-color underline"
                    >
                        Google Lighthouse Report
                    </a>
                </p>
            </Hello>

            <div className="h-screen" ref={$sliderSection}>
                <div className="flex min-h-screen flex-col pt-14 lg:pt-24">
                    <TitleContent
                        title="The Challenge"
                        className={"mb-10 lg:mb-16"}
                    >
                        <p>
                            FLS Group needed a website that not only highlighted
                            their services but also resonated with their
                            rebranding strategy. The challenge was creating a
                            site that was multilingual, SEO-optimised, and
                            highly website’s performance, all while maintaining
                            an elegant design.
                        </p>
                    </TitleContent>

                    <div className="relative flex-1 overflow-hidden">
                        <div className="absolute inset-0">
                            <div
                                className="mandala-slider relative h-full w-full overflow-hidden will-change-transform"
                                ref={$slider}
                            >
                                {sliderImages.map((image, index) => {
                                    const ImageComponent = image.component

                                    return (
                                        <div
                                            className="slider-wrapper absolute inset-0"
                                            style={{
                                                zIndex: 10 - index
                                            }}
                                        >
                                            <div className="slider-outer relative h-full  w-full overflow-hidden will-change-transform">
                                                <div className="slider-inner absolute inset-0 will-change-transform">
                                                    <div
                                                        className={`slider-image relative h-full w-full bg-cover bg-center will-change-transform`}
                                                    >
                                                        <ImageComponent />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="absolute inset-0 z-10">
                                                <div className="relative flex h-full w-full flex-col pt-10 lg:justify-center lg:pt-0">
                                                    <div
                                                        className={`container flex flex-col lg:block ${
                                                            index
                                                                ? ""
                                                                : "justify-center"
                                                        }`}
                                                    >
                                                        {index ? (
                                                            <StaticImage
                                                                src="../../../static/case-study/fls-logo.png"
                                                                className={`slider-logo mb-6`}
                                                                objectFit="contain"
                                                            />
                                                        ) : (
                                                            <StaticImage
                                                                src="../../../static/case-study/fls-logo-tagline.png"
                                                                className={`slider-logo mb-6`}
                                                                objectFit="contain"
                                                            />
                                                        )}

                                                        <h2 className="slider-text max-w-md text-lg font-semibold tracking-wide text-white lg:max-w-lg lg:text-[32px] lg:leading-[45px]">
                                                            {image.text}
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div
                            className="absolute bottom-0 left-0 h-1 w-full origin-left bg-white"
                            ref={$progress}
                        ></div>
                    </div>
                </div>
            </div>

            {/* Solution */}
            <TitleContent
                title="The Solutions"
                className="mt-14 mb-10 lg:mt-24 lg:mb-16"
            >
                <p className="">
                    We embraced Gatsby.js for its SEO-friendly and efficient
                    performance. Tailwind CSS was used for its flexibility in
                    web design, allowing us to create a visually appealing and
                    interactive user experience. The 3D globe feature was
                    meticulously developed using Three.js, and the website's
                    content was dynamically managed through Headless WordPress.
                </p>
            </TitleContent>

            {/* banner 2 */}
            <div className="container mb-10 grid gap-y-10 gap-x-[30px] md:grid-cols-2 lg:mb-16 lg:gap-x-24">
                <div className="m-0 aspect-[475/356] w-full overflow-hidden lg:ml-5 ">
                    <StaticImage
                        src="../../../static/case-study/fls/banner-1.png"
                        className="h-full w-full duration-1000 hover:scale-105"
                        alt=""
                        objectFit="cover"
                    />
                </div>
                <div className="m-0 aspect-[475/356] w-full overflow-hidden lg:mr-5 ">
                    <StaticImage
                        src="../../../static/case-study/fls/banner-2.png"
                        className="h-full w-full object-cover transition-transform duration-1000 hover:scale-105"
                        alt=""
                        objectFit="cover"
                    />
                </div>
            </div>

            <Newsletter context={context} />

            {/* Out Come */}
            <TitleContent
                title="Outcomes"
                className={"mb-10 mt-14 lg:mb-16 lg:mt-24"}
            >
                <p>
                    Saigon Digital seamlessly blended modern design and
                    functionality for FLS, resulting in an efficient, innovative
                    digital experience. The website symbolises FLS Group's
                    innovation, integrates rebranding goals, and boosts their
                    presence in the logistics and shipping industry.
                </p>
            </TitleContent>
            {/* Content image wrapper */}
            <div ref={$contentImageWrapper}>
                <div
                    className="relative lg:sticky lg:top-[20vh] lg:h-screen"
                    ref={$contentImagePin}
                >
                    <ContentImage
                        className="lg:opacity-100"
                        img={"/case-study/fls/content-image-1.png"}
                    >
                        <h2 className="mb-4 text-2xl font-semibold">
                            Blending Functionality with Innovation
                        </h2>

                        <ul className="list-disc pl-5">
                            <li>
                                In our collaboration with FLS, Saigon Digital
                                crafted a harmonious blend of contemporary
                                design and practical functionality. This ensured
                                that the user experience was smoothly seamless,
                                whether you're browsing on a laptop in a cosy
                                café or tapping away on your phone
                            </li>
                            <li>
                                Saigon Digital interlaced cutting-edge Headless
                                CMS technology with sleek design, creating a
                                digital experience that's remarkably efficient.
                                This approach perfectly embodies the Saigon
                                Digital ethos: blending innovation with
                                user-friendly solutions in the ever-evolving
                                digital landscape.
                            </li>
                        </ul>
                    </ContentImage>
                    <ContentImage img={"/case-study/fls/content-image-2.png"}>
                        <h2 className="mb-4 text-2xl font-semibold">
                            Embarking on a Digital Voyage
                        </h2>

                        <p>
                            The new website for FLS Group stands as a symbol of
                            their innovative spirit, seamlessly integrating
                            their rebranding objectives with our digital
                            expertise. The interactive features, like the 3D
                            globe, not only highlight their global reach but
                            also engage visitors in an immersive online
                            experience.
                        </p>
                    </ContentImage>
                    <ContentImage img={"/case-study/fls/content-image-3.png"}>
                        <h2 className="mb-4 text-2xl font-semibold">
                            Business Impact
                        </h2>

                        <p>
                            The website has become a cornerstone in FLS Group's
                            digital strategy, effectively enhancing their
                            brand’s digital presence in the logistics and
                            shipping industry.
                        </p>
                    </ContentImage>
                </div>
                <div className="lg:h-[300vh]"></div>
            </div>

            <TitleContent
                title="The Results"
                className={"mb-10 lg:mb-16 lg:-mt-[30vh]"}
            >
                <p>
                    In this project, our team at Saigon Digital has once again
                    demonstrated our capability to transform and elevate a
                    brand's digital presence. The FLS Group website redesign is
                    a testament to our commitment to delivering innovative and
                    impactful digital solutions.
                </p>
            </TitleContent>
            <FlsBanner className={"mt-16 lg:mt-24 lg:h-[460px]"}>
                <StaticImage
                    src={
                        "../../../static/case-study/fls/full-width-banner-3.png"
                    }
                    alt="FLS mockup"
                    objectFit="cover"
                    className="hidden h-full w-full lg:block"
                />

                <StaticImage
                    src={
                        "../../../static/case-study/fls/full-width-banner-mobile-3.png"
                    }
                    alt="FLS mockup"
                    objectFit="cover"
                    className="h-full w-full lg:hidden"
                />
            </FlsBanner>
            <ShowCase data={caseStudies} />
        </Layout>
    )
}

export default Detail

export const Head = () => (
    <SEO
        title={"FLS Group | Saigon Digital"}
        description={
            "FLS Group, a leading player in the logistics and shipping industry, is known for their reliable and efficient services. Their commitment to excellence has established them as a prominent figure in global logistics."
        }
        keywords={
            "web design saigon, web development saigon, jamstack development, jamstack web development saigon"
        }
        image={"/case-study/fls/Open_Graph.png"}
    />
)
