import { StaticImage } from "gatsby-plugin-image"
import React from "react"

export const FLSImageOne = () => {
    return (
        <div className="h-full w-full">
            <StaticImage
                src={"../../../static/case-study/fls/slider-1.png"}
                alt="fls mockup"
                objectFit="cover"
                className="hidden h-full w-full lg:block"
            />
            <StaticImage
                src={"../../../static/case-study/fls/slider-mobile-1.png"}
                alt="fls mockup"
                objectFit="cover"
                className="h-full w-full lg:hidden"
            />
        </div>
    )
}
export const FLSImageTwo = () => {
    return (
        <div className="h-full w-full">
            <StaticImage
                src={"../../../static/case-study/fls/slider-2.png"}
                alt="fls mockup"
                objectFit="cover"
                className="hidden h-full w-full lg:block"
            />
            <StaticImage
                src={"../../../static/case-study/fls/slider-mobile-2.png"}
                alt="fls mockup"
                objectFit="cover"
                className="h-full w-full lg:hidden"
            />
        </div>
    )
}
export const FLSImageThree = () => {
    return (
        <div className="h-full w-full">
            <StaticImage
                src={"../../../static/case-study/fls/slider-3.png"}
                alt="fls mockup"
                objectFit="cover"
                className="hidden h-full w-full lg:block"
            />
            <StaticImage
                src={"../../../static/case-study/fls/slider-mobile-3.png"}
                alt="fls mockup"
                objectFit="cover"
                className="h-full w-full lg:hidden"
            />
        </div>
    )
}
export const FLSImageFour = () => {
    return (
        <div className="h-full w-full">
            <StaticImage
                src={"../../../static/case-study/fls/slider-4.png"}
                alt="fls mockup"
                objectFit="cover"
                className="hidden h-full w-full lg:block"
            />
            <StaticImage
                src={"../../../static/case-study/fls/slider-mobile-4.png"}
                alt="fls mockup"
                objectFit="cover"
                className="h-full w-full lg:hidden"
            />
        </div>
    )
}
