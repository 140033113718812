import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import React, { useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"

if (typeof window !== "undefinded") {
    gsap.registerPlugin(ScrollTrigger)
}

const FlsBanner = ({ className, children }) => {
    const $wrapper = useRef(null)
    const $image = useRef(null)
    useEffect(() => {
        const mm = gsap.matchMedia($wrapper)
        mm.add("(min-width: 1024px)", () => {
            gsap.to(
                $image.current,

                {
                    scale: 1.05,
                    scrollTrigger: {
                        trigger: $wrapper.current,
                        scrub: 1,
                        // markers: true,
                        start: "top center",
                        end: "bottom center"
                    },
                    ease: "none"
                }
            )
        })

        return () => {
            mm.revert()
        }
    }, [])

    return (
        <div
            className={`relative ${className} h-[50vh] overflow-hidden lg:w-full`}
            ref={$wrapper}
        >
            <div className={`absolute inset-0 h-full w-full`} ref={$image}>
                {children}
            </div>
        </div>
    )
}

export default FlsBanner